
import { ref } from '@vue/reactivity'
import QA from "../components/Q&A.vue" 
export default {
    name: "Help",
    components: {
        QA
    },
    setup() {
        const title = ref("如何購買")
        return {
            title
        }
    }
}
