
// @ is an alias to /src
import smalllogoimg from "@/assets/smalllogo.svg"
import logo from "@/assets/logo.svg"
export default {
  name: 'Home',
  components: {
  },
  setup() {
    return {
      smalllogoimg,
      logo
    }
  }
}
