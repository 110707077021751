
import { reactive, ref, toRef } from '@vue/reactivity';
export default {
    name: "NFT_card",
    props: [
        'Name', 
        "Description", 
        "ImgURL", 
        "Price",
        "TokenId", 
        "Contract_address",
        "Button_words",
        "Level",
    ],
    setup(props) {
        const name = toRef(props, 'Name');
        const description = toRef(props, 'Description');
        const imgURL = toRef(props, 'ImgURL');
        const tokenId = toRef(props, "TokenId")
        const contract_address = toRef(props, "Contract_address")
        const price = toRef(props, 'Price')
        const button_words = toRef(props, 'Button_words')
        const level = toRef(props, "Level")
        const levelWord = ref("")

        const link = "/" + contract_address.value + "/" + tokenId.value

        const cardShadow = reactive({
            defaultLevel: level.value == -1 || !(level.value),
            GLevel: level.value == 0,
            FLevel: level.value == 1,
            ELevel: level.value == 2,
            DLevel: level.value == 3,
            CLevel: level.value == 4,
            BLevel: level.value == 5,
            ALevel: level.value == 6,
            SLevel: level.value == 7,
            SSLevel: level.value == 8,
        })

        const cardWords = reactive({
            defaultLevelWord: level.value == -1 || !(level.value),
            GLevelWord: level.value == 0,
            FLevelWord: level.value == 1,
            ELevelWord: level.value == 2,
            DLevelWord: level.value == 3,
            CLevelWord: level.value == 4,
            BLevelWord: level.value == 5,
            ALevelWord: level.value == 6,
            SLevelWord: level.value == 7,
            SSLevelWord: level.value == 8,
        })

        switch (level.value) {
            case 0:
                levelWord.value = "G";
                break;
            case 1:
                levelWord.value = "F";
                break;
            case 2:
                levelWord.value = "E";
                break;
            case 3:
                levelWord.value = "D";
                break;
            case 4:
                levelWord.value = "C";
                break;
            case 5:
                levelWord.value = "B";
                break;
            case 6:
                levelWord.value = "A";
                break;
            case 7:
                levelWord.value = "S";
                break;
            case 8:
                levelWord.value = "SS";
                break;
        }
        // console.log(contract_address)
        return {
            name,
            description,
            imgURL,
            price,
            tokenId,
            contract_address,
            link,
            button_words,
            level,
            cardShadow,
            cardWords,
            levelWord
        }
    },
}
