
import { reactive, ref } from '@vue/reactivity'
import Moapi from "../Moralis/Marolis"
import NFTCard from "../components/NFT_card.vue"

export default {
    name: 'Explore',
    components: {
        NFTCard,
    },
    setup() {
        const IpfsPreLink = ref("https://cloudflare-ipfs.com/ipfs/")
        const NFTContractAddressList = ref([])
        const NFT_List = ref([])
        const Selected = ref("All")
        const ImageSet = new Set()

        Moapi.getTotalContractNumber().then((res) => {
            for (let i = 0; i < res; i++) {
                NFTContractAddressList.value.push(Moapi.getWhiteList(i))
            }
            Promise.all(NFTContractAddressList.value).then(Lists => {
                Lists.forEach(element => {
                    Moapi.ContractgetAllTokenIds(element).then((res) => {
                        return res.result
                    }).then((result) => {
                        // console.log(result)
                        const OneNFTContract = {
                            NFT_name: ref(""),
                            contract_address: ref(0),
                            NFT_totalSupply: reactive([])
                        }
                        OneNFTContract.NFT_name.value = result[0].name
                        OneNFTContract.contract_address.value = result[0].token_address
                        result.forEach(element => {
                            if (!element.metadata) {
                                Moapi.getNFTMetadataFromTokenUri(element.TokenId).then((metadataReturn) => {
                                    element.metadata = metadataReturn
                                    if (ImageSet.has(element.metadata)) {
                                        // console.log("Had!!")
                                    } else {
                                        ImageSet.add(element.metadata)
                                        const metadatas = JSON.parse(element.metadata)
                                        if (metadatas.image.substring(0, 7) === "ipfs://") {
                                            metadatas.image = metadatas.image.substr(7)
                                        }
                                        const SingleNFT = reactive({
                                            name: ref(metadatas.name),
                                            description: ref(metadatas.description),
                                            Img: ref(IpfsPreLink.value + metadatas.image),
                                            TokenId: ref(element.token_id),
                                            Level: 0,
                                            Price: 0.8,
                                        })
                                        if (metadatas.attributes) {
                                            metadatas.attributes.forEach((objects) => {
                                                SingleNFT[objects.trait_type] = objects.value
                                            })
                                        }
                                        // console.log("SingleNFT",SingleNFT)
                                        OneNFTContract.NFT_totalSupply.push(SingleNFT)
                                    }
                                    // console.log("Image", ImageSet)
                                })
                            } else {
                                if (ImageSet.has(element.metadata)) {
                                    // console.log("Had!!")
                                } else {
                                    ImageSet.add(element.metadata)
                                    const metadatas = JSON.parse(element.metadata)
                                    if (metadatas.image.substring(0, 7) === "ipfs://") {
                                        metadatas.image = metadatas.image.substr(7)
                                    }
                                    const SingleNFT = reactive({
                                        name: ref(metadatas.name),
                                        description: ref(metadatas.description),
                                        Img: ref(IpfsPreLink.value + metadatas.image),
                                        TokenId: ref(element.token_id),
                                        Level: 0,
                                        Price: 0.8,
                                    })
                                    if (metadatas.attributes) {
                                        metadatas.attributes.forEach((objects) => {
                                            SingleNFT[objects.trait_type] = objects.value
                                        })
                                    }
                                    // console.log("SingleNFT",SingleNFT)
                                    OneNFTContract.NFT_totalSupply.push(SingleNFT)
                                }
                                // console.log("Image", ImageSet)
                            }
                        })
                        NFT_List.value.push(OneNFTContract)
                    })
                })
            })
        })
            

        

        function changeSelected(tar) {
            Selected.value = tar
        }

        function shortenWords(Words) {
            if (Words.length < 20) {
                return Words
            } else {
                return Words.substr(15) + "..."
            }
        }

        return {
            IpfsPreLink,
            NFT_List,
            Selected,
            changeSelected,
            shortenWords,
        }
    }
}
