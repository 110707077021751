
import { toRef } from '@vue/reactivity'
export default {
    name: "Q&A",
    props: ["Title", "Question", "Answer", "Author"],
    setup(props) {
        const title = toRef(props, "Title")
        const question = toRef(props, "Question")
        const answer = toRef(props, "Answer")
        const author = toRef(props, "Author")

        return {
            title,
            question,
            answer,
            author,
        }
    }
}
